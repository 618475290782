import emailjs from "@emailjs/browser";

class Config {
    public emailServiceId = "service_0xf17ql";
    public emailTemplateId = "template_gr4rjej";
    public publicKey = "-6ToGdTsPBB3T9aag"
    public mailService = emailjs.init(this.publicKey);
    public reCaptchaKey = "6LeAmh0lAAAAAKBz6nOsIy-Rbls9bGv84DxNoME1"
}


const appConfig = new Config();

export default appConfig;
